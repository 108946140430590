import React from 'react';
import App, { queryClient } from './App';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import makeMirageServer from '../src/mirage/server';
import { createServer, Response } from 'miragejs';

declare global {
    interface Window {
        Cypress: any;
        queryClient: any;
        handleFromCypress: any;
    }
}

// Remove comment below to not run Mirage in production
// if (process.env.NODE_ENV === 'development') {
makeMirageServer({ environment: 'development' });
// }

ReactDOM.render(<App />, document.getElementById('root'));

// Proxy network requests and expose queryClient when Cypress is running
if (window.Cypress) {
    window.queryClient = queryClient;
    queryClient.setDefaultOptions({
        queries: {
            retry: false,
        },
    });

    let otherDomains = ['https://api-dev.mediabank.me/'];
    let methods = ['get', 'put', 'patch', 'post', 'delete'];

    createServer({
        environment: 'test',
        routes() {
            for (const domain of ['/', ...otherDomains]) {
                for (const method of methods) {
                    // @ts-ignore
                    this[method](`${domain}*`, async (schema: any, request: any) => {
                        let [status, headers, body] = await window.handleFromCypress(request);
                        return new Response(status, headers, body);
                    });
                }
            }
            this.passthrough(process.env.REACT_APP_JWT_URL + '/login');
            this.passthrough(process.env.REACT_APP_JWT_URL + '/');
            this.passthrough(process.env.REACT_APP_JWT_URL + '/refresh');
        },
    });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
