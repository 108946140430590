import { Filter } from '../../lib/Filter';
import { CriteriaFilter } from '../../lib/Criteria';

export const mockFilters: CriteriaFilter[] = [
    { SegmentType: '1st half', CameraSource: 'Multilateral' },
    { ContentType: ['News Feed', 'Match clip'] },
    { MediaType: 'Video' },
];

export const mockFilterItems = {
    CameraSource: ['Multilateral'],
    SegmentType: ['Match ID', '1st half'],
    ContentType: ['Infographics', 'News Exchange Feed', 'Match clip'],
    MediaType: ['Video'],
};

export const mockFiltersMapped: Filter[] = mockFilters.map(filterObject =>
    Object.keys(filterObject).map(key => {
        const metaValue = filterObject[key];
        return { metaKey: key, metaValue: typeof metaValue === 'string' ? [metaValue] : metaValue };
    })
);
