export interface MirageUser {
    id: string;
    name: string;
}

export const mockMirageUsers: MirageUser[] = [
    {
        id: '1',
        name: 'Kari Nordmann',
    },
    {
        id: '2',
        name: 'Ola Nordmann',
    },
    {
        id: '3',
        name: 'Per Nordmann',
    },
    {
        id: '4',
        name: 'Pål Nordmannn',
    },
    {
        id: '5',
        name: 'Espen Nordmannn',
    },
    {
        id: '6',
        name: 'Jan Nordmannn',
    },
    {
        id: '7',
        name: 'Jens Nordmannn',
    },
    {
        id: '8',
        name: 'Marte Nordmannn',
    },
    {
        id: '9',
        name: 'Ida Nordmannn',
    },
    {
        id: '10',
        name: 'Mia Nordmannn',
    },
    {
        id: '11',
        name: 'Emma Nordmannn',
    },
    {
        id: '12',
        name: 'Alex Nordmannn',
    },
    {
        id: '13',
        name: 'Nils Nordmannn',
    },
    {
        id: '14',
        name: 'Tone Nordmannn',
    },
    { id: '16285', name: 'Read-Only User E2E Tests' }, // readonly-nms user
    { id: '15780', name: 'E2E Test User, Local admin' }, // admin-nms user
    { id: '14715', name: 'Bujar Deari' },
    { id: '99999999999', name: 'Dev Default User' },
];
