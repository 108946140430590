import { ServerGlobalSettings, AllGlobalSettings, SettingsProperties } from '../../lib/PosterSettings';
import { allFields } from '../../constants/overlaySettings';
import { Collection, Instantiate } from 'miragejs';
import { AppRegistry } from '../server';

type BulkEditFields = (keyof SettingsProperties)[] | null;

export const checkBulkEdit = (initialGlobalSettings: AllGlobalSettings, newGlobalSettings: AllGlobalSettings) => ({
    bulkEditCollectionFields: getBulkEditFields(
        initialGlobalSettings.collection,
        newGlobalSettings.collection
    ) as BulkEditFields,
    bulkEditSubcollectionFields: getBulkEditFields(
        initialGlobalSettings.subCollection,
        newGlobalSettings.subCollection
    ) as BulkEditFields,
});

const getBulkEditFields = (initialGlobalSettings: ServerGlobalSettings, newGlobalSettings: ServerGlobalSettings) => {
    let fields = [];
    const nonEditableFields = allFields.filter(field => !newGlobalSettings.editable.includes(field));

    for (let i = 0; i < allFields.length; i++) {
        const hasChangedToNonEditable =
            initialGlobalSettings.editable.includes(allFields[i]) && !newGlobalSettings.editable.includes(allFields[i]);

        const hasUpdatedNonEditableField =
            nonEditableFields.includes(allFields[i]) &&
            initialGlobalSettings.settings[allFields[i]] !== newGlobalSettings.settings[allFields[i]];

        if (hasChangedToNonEditable || hasUpdatedNonEditableField) fields.push(allFields[i]);
    }

    return fields.length > 0 ? fields : null;
};

export const updatePosterSettingsFields = (
    mirageCollections: Collection<Instantiate<AppRegistry, 'collection' | 'subcollection'>>,
    bulkEditFields: BulkEditFields,
    newGlobalSettings: AllGlobalSettings
) => {
    const modelName = mirageCollections.modelName === 'collection' ? 'collection' : 'subCollection';

    mirageCollections.models.forEach(mirageCollection => {
        bulkEditFields!.forEach(field => {
            if (mirageCollection.poster?.[field] !== undefined)
                (mirageCollection.poster[field] as string | number | boolean) = newGlobalSettings[modelName].settings[
                    field
                ];
        });
    });
};

export const updateDefaultSettingsFields = (
    mirageCollections: Collection<Instantiate<AppRegistry, 'collection'>>,
    bulkEditFields: BulkEditFields,
    newGlobalSettings: AllGlobalSettings
) => {
    mirageCollections.models.forEach(mirageCollection => {
        bulkEditFields!.forEach(field => {
            if (mirageCollection.settings) {
                (mirageCollection.settings[field] as
                    | string
                    | number
                    | boolean) = newGlobalSettings.subCollection.settings[field];
            }
        });
    });
};
