import { belongsTo, createServer, hasMany, Model, Registry, JSONAPISerializer, Response, Serializer } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import Schema from 'miragejs/orm/schema';
import { camelCase } from 'lodash';
import { checkBulkEdit, updatePosterSettingsFields, updateDefaultSettingsFields } from './helpers/bulkEdit';
import {
    reorderPositions,
    increasePositionsGreaterThan,
    decreasePositionsGreaterThan,
} from './helpers/updatePositions';
import {
    addCreatedByAndUpdatedByProperties,
    sortSubcollections,
    addPosterAssetUrl,
    pickFieldSet,
} from './helpers/collectionSerialize';
import { mockMirageCollections, MirageCollection } from './fixtures/collections';
import { mockMirageSubcollections, MirageSubcollection } from './fixtures/subcollections';
import { mockMirageGlobalSettings } from './fixtures/globalSettings';
import { AllGlobalSettings } from '../lib/PosterSettings';
import { MirageUser, mockMirageUsers } from './fixtures/users';
import { getMirageUser } from './helpers/user';
import jwtDecode from 'jwt-decode';
import { Token } from '../lib/Token';

const UserModel: ModelDefinition<MirageUser> = Model.extend({});
const CollectionModel: ModelDefinition<MirageCollection> = Model.extend({});
const SubcollectionModel: ModelDefinition<MirageSubcollection> = Model.extend({});
const GlobalSettingsModel: ModelDefinition<AllGlobalSettings> = Model.extend({});

export type AppRegistry = Registry<
    {
        user: typeof UserModel;
        collection: typeof CollectionModel;
        subcollection: typeof SubcollectionModel;
        globalSettings: typeof GlobalSettingsModel;
    },
    {}
>;
type AppSchema = Schema<AppRegistry>;

const makeMirageServer = ({ environment = 'test' } = {}) => {
    let ApplicationSerializer = JSONAPISerializer.extend({
        alwaysIncludeLinkageData: true,
        keyForAttribute(attr) {
            return camelCase(attr);
        },
    });

    return createServer({
        environment,

        models: {
            user: Model,
            collection: Model.extend({
                subcollections: hasMany(),
                createdBy: belongsTo('user'),
                updatedBy: belongsTo('user'),
            }),
            subcollection: Model.extend({
                collection: belongsTo(),
            }),
            globalSettings: Model,
        },

        fixtures: {
            users: mockMirageUsers,
            collections: mockMirageCollections,
            subcollections: mockMirageSubcollections,
            globalSettings: mockMirageGlobalSettings,
        },

        serializers: {
            application: ApplicationSerializer,
            collection: (ApplicationSerializer as typeof JSONAPISerializer).extend({
                serialize(object, request) {
                    // @ts-ignore
                    let json = Serializer.prototype.serialize.apply(this, arguments);

                    json.data = addCreatedByAndUpdatedByProperties(json, object);
                    if (request.queryParams['fields[collections]']) json.data = pickFieldSet(json, request);
                    if (json.included?.length) json = sortSubcollections(json);

                    return json;
                },
            }),
            subcollection: (ApplicationSerializer as typeof JSONAPISerializer).extend({
                serialize(_object, request) {
                    // @ts-ignore
                    let json = Serializer.prototype.serialize.apply(this, arguments);

                    if (request.queryParams['fields[collections]']) json.included = pickFieldSet(json, request);

                    return json;
                },
            }),
        },

        routes() {
            this.urlPrefix = process.env.REACT_APP_API_URL + '/api/v1';

            // fetch collections
            this.get('/collections', (schema: AppSchema) =>
                schema.all('collection').sort((a, b) => a.position - b.position)
            );

            // fetch single collection
            this.get('/collections/:id', (schema: AppSchema, request) => {
                const id = request.params.id;
                const response = schema.find('collection', id);
                return response === null ? new Response(404) : response;
            });

            // fetch single sub-collection
            this.get('/collections/:collectionid/subcollections/:id', (schema: AppSchema, request) => {
                const id = request.params.id;
                const collectionid = request.params.collectionid;
                const response = schema.findBy('subcollection', { collectionId: collectionid, id: id });
                return response === null ? new Response(404) : response;
            });

            // create new collection
            this.post('/collections', (schema: AppSchema, request) => {
                const token = jwtDecode<Token>(request.requestHeaders.Authorization);
                const user = getMirageUser(token.user.userid);
                const attrs = JSON.parse(request.requestBody).data.attributes;
                const position = schema.all('collection').length + 1;
                const newCollection = {
                    ...attrs,
                    position: position,
                    createdById: user.id,
                    updatedById: user.id,
                    poster: addPosterAssetUrl(attrs.poster),
                };
                schema.create('collection', newCollection);
                return schema.findBy('collection', { position: position })!;
            });

            // create new sub-collection
            this.post('/collections/:collectionid/subcollections', (schema: AppSchema, request) => {
                const collectionid = request.params.collectionid;
                const attrs = JSON.parse(request.requestBody).data.attributes;

                // remove criteria from parent collection
                let collection = schema.find('collection', collectionid)!;
                if (collection.attrs.criteria) {
                    collection.attrs.criteria = undefined;
                    collection.save();
                }

                const position = schema.where('subcollection', { collectionId: collectionid }).length + 1;
                const newSubcollection = {
                    ...attrs,
                    collectionId: collectionid,
                    position: position,
                    poster: addPosterAssetUrl(attrs.poster),
                };
                schema.create('subcollection', newSubcollection);
                return schema.findBy('subcollection', { position: position, collectionId: collectionid })!;
            });

            // update or reorder collection
            this.patch('/collections/:id', (schema: AppSchema, request) => {
                const token = jwtDecode<Token>(request.requestHeaders.Authorization);
                const user = getMirageUser(token.user.userid);
                const id = request.params.id;
                const attrs = JSON.parse(request.requestBody).data.attributes;

                let collection = schema.find('collection', id)!;
                if ('viewable' in attrs) {
                    collection.update({ viewable: attrs.viewable, updatedById: user.id });
                } else if ('position' in attrs) {
                    let allCollections = schema.all('collection');
                    reorderPositions(allCollections, collection.position, attrs.position);
                    allCollections.save();
                    collection.update({ position: attrs.position, updatedById: user.id });
                } else {
                    if ('criteria' in attrs && collection.attrs.subcollectionIds) {
                        collection.attrs.subcollectionIds = null;
                        collection.attrs.settings = undefined;
                    }
                    if (!('criteria' in attrs) && collection.attrs.criteria) {
                        collection.attrs.criteria = undefined;
                    }
                    collection.save();

                    collection.update({
                        ...attrs,
                        updatedById: user.id,
                        ...('poster' in attrs && { poster: addPosterAssetUrl(attrs.poster) }),
                    });
                }

                return collection;
            });

            // delete collection
            this.delete('/collections/:id', (schema: AppSchema, request) => {
                const id = request.params.id;
                let allCollections = schema.all('collection');
                let deleteCollection = schema.find('collection', id)!;

                decreasePositionsGreaterThan(allCollections, deleteCollection);
                allCollections.save();
                deleteCollection.destroy();

                return new Response(204);
            });

            // delete sub-collection
            this.delete('/collections/:collectionid/subcollections/:subcollectionid', (schema: AppSchema, request) => {
                const parentId = request.params.collectionid;
                const id = request.params.subcollectionid;
                let siblingSubcollections = schema.where(
                    'subcollection',
                    subcollection => subcollection.collectionId === parentId
                );
                let deleteCollection = schema.find('subcollection', id)!;

                if (siblingSubcollections.length > 1) {
                    decreasePositionsGreaterThan(siblingSubcollections, deleteCollection);
                    siblingSubcollections.save();
                } else {
                    let collection = schema.find('collection', parentId)!;
                    if (collection.attrs.settings) collection.attrs.settings = undefined;
                    collection.save();
                }
                deleteCollection.destroy();

                return new Response(204);
            });

            // update or reorder sub-collection
            this.patch('/collections/:collectionid/subcollections/:subcollectionid', (schema: AppSchema, request) => {
                const parentId = request.params.collectionid;
                const subcollectionId = request.params.subcollectionid;
                const attrs = JSON.parse(request.requestBody).data.attributes;

                let subcollection = schema.find('subcollection', subcollectionId)!;
                if ('position' in attrs) {
                    let siblingSubcollections = schema.where(
                        'subcollection',
                        subcollection => subcollection.collectionId === parentId
                    );
                    reorderPositions(siblingSubcollections, subcollection.position, attrs.position);
                    siblingSubcollections.save();
                    subcollection.update('position', attrs.position);
                } else {
                    subcollection.update({ ...attrs, poster: addPosterAssetUrl(attrs.poster) });
                }

                return subcollection;
            });

            // copy collection
            this.post('/collections/:id/copy', (schema: AppSchema, request) => {
                const copyId = request.params.id;
                const token = jwtDecode<Token>(request.requestHeaders.Authorization);
                const user = getMirageUser(token.user.userid);
                const copyCollection = schema.find('collection', copyId)!;
                let allCollections = schema.all('collection');

                let { id, ...newCollection } = copyCollection.attrs;
                newCollection.label += ' Copy';
                newCollection.createdById = user.id;
                newCollection.updatedById = user.id;
                newCollection.position = copyCollection.position + 1;

                increasePositionsGreaterThan(allCollections, copyCollection);
                allCollections.save();
                schema.create('collection', newCollection);

                return newCollection;
            });

            // copy sub-collection
            this.post('/collections/:collectionid/subcollections/:subcollectionid', (schema: AppSchema, request) => {
                const parentId = request.params.collectionid;
                const copyId = request.params.subcollectionid;
                const copySubcollection = schema.find('subcollection', copyId)!;
                let siblingSubcollections = schema.where(
                    'subcollection',
                    subcollection => subcollection.collectionId === parentId
                );

                let { id, ...newSubcollection } = copySubcollection.attrs;
                newSubcollection.label += ' Copy';
                newSubcollection.position = copySubcollection.position + 1;

                increasePositionsGreaterThan(siblingSubcollections, copySubcollection);
                siblingSubcollections.save();
                schema.create('subcollection', newSubcollection);

                return newSubcollection;
            });

            // share collection
            this.post('/collections/:id/share', (schema: AppSchema, request) => new Response(201));

            // fetch global settings
            this.get('/config/customer/CollectionsSettings', (schema: AppSchema) => ({
                data: {
                    attributes: { CollectionsSettings: JSON.stringify(schema.first('globalSettings')!) },
                },
            }));

            // save global settings
            this.patch('/globalsettings', (schema: AppSchema, request) => {
                const { data: newGlobalSettings } = JSON.parse(request.requestBody);
                const oldGlobalSettings = schema.first('globalSettings')!;
                const { bulkEditCollectionFields, bulkEditSubcollectionFields } = checkBulkEdit(
                    oldGlobalSettings,
                    newGlobalSettings
                );

                if (bulkEditCollectionFields) {
                    let allCollections = schema.all('collection');
                    updatePosterSettingsFields(allCollections, bulkEditCollectionFields, newGlobalSettings);
                    allCollections.save();
                }

                if (bulkEditSubcollectionFields) {
                    let allSubcollections = schema.all('subcollection');
                    let allCollections = schema.all('collection');

                    updatePosterSettingsFields(allSubcollections, bulkEditSubcollectionFields, newGlobalSettings);
                    allSubcollections.save();

                    updateDefaultSettingsFields(allCollections, bulkEditSubcollectionFields, newGlobalSettings);
                    allCollections.save();
                }
                schema.first('globalSettings')?.update(newGlobalSettings);

                return { attributes: schema.first('globalSettings')! };
            });

            // authWithCred
            this.passthrough(process.env.REACT_APP_JWT_URL + '/login');

            // validateToken
            this.passthrough(process.env.REACT_APP_JWT_URL + '/');

            // refreshToken
            this.passthrough(process.env.REACT_APP_JWT_URL + '/refresh');

            // All requests to mediabank-rest-map
            this.passthrough(process.env.REACT_APP_API_URL + '/api/v1/**');
        },
    });
};

export default makeMirageServer;
