import { Subcollection } from '../../lib/Collection';
import { Criteria } from '../../lib/Criteria';
import { DeepPartial } from '../../lib/Utility';
import { mockSubcollectionPoster, mockSubcollectionPosterWithSettings } from './posters';
import { mockSmartSearchesMapped } from './smartSearches';
import { mockFilters } from './filters';
import { mockMetaKeys } from './metaKeys';

export interface MirageSubcollection
    extends Omit<Subcollection, 'searchId' | 'groupBy' | 'filters' | 'parentCollection'> {
    criteria: Criteria;
    collectionId?: string;
}

export const mockMirageSubcollection = (subcollection: DeepPartial<MirageSubcollection>): MirageSubcollection => ({
    id: '1',
    position: 1,
    label: 'Goals',
    description: 'Description text',
    ...subcollection,
    criteria: {
        searchId: mockSmartSearchesMapped[1].id,
        ...subcollection.criteria,
    },
    poster: {
        ...mockSubcollectionPoster({ mockPosterIndex: 0 }),
        ...subcollection.poster,
    },
});

export const mockMirageSubcollections: MirageSubcollection[] = [
    mockMirageSubcollection({
        collectionId: '1',
        description: 'Goals',
        criteria: {
            searchId: mockSmartSearchesMapped[1].id,
            groupBy: mockMetaKeys[0],
            filters: [mockFilters[0]],
        },
    }),
    mockMirageSubcollection({
        id: '2',
        position: 2,
        collectionId: '1',
        label: 'Highlights',
        description: 'Highlights',
        poster: mockSubcollectionPosterWithSettings({
            mockPosterIndex: 2,
            subTitleVisible: true,
            subTitleText: 'Highlights subtitle',
            overlayPosition: 'center',
            overlayTextSize: 'large',
        }),
        criteria: {
            searchId: mockSmartSearchesMapped[3].id,
            groupBy: mockMetaKeys[0],
            filters: [mockFilters[2]],
        },
    }),
    mockMirageSubcollection({
        id: '3',
        position: 3,
        collectionId: '1',
        label: 'News',
        description: 'News',
        poster: mockSubcollectionPoster({
            mockPosterIndex: 1,
            subTitleVisible: true,
            subTitleText: 'News 2022',
        }),
        criteria: {
            searchId: mockSmartSearchesMapped[2].id,
            groupBy: mockMetaKeys[0],
            filters: [mockFilters[1]],
        },
    }),
    mockMirageSubcollection({
        id: '4',
        position: 4,
        collectionId: '1',
        label: 'Champions League Sub-collection',
        description: 'Highlights from 2021',
        poster: mockSubcollectionPosterWithSettings({
            mockPosterIndex: 1,
            overlayText: 'CL 2021 Highlights',
            overlayPosition: 'top',
            overlayTextSize: 'small',
        }),
        criteria: {
            searchId: mockSmartSearchesMapped[2].id,
            groupBy: mockMetaKeys[0],
            filters: mockFilters,
        },
    }),
    mockMirageSubcollection({
        id: '5',
        position: 1,
        collectionId: '4',
        label: 'Pre-season',
        description: 'Pre-season 20222',
        poster: mockSubcollectionPoster({
            mockPosterIndex: 3,
        }),
    }),
    mockMirageSubcollection({
        id: '6',
        position: 2,
        collectionId: '4',
        description: 'Goals 2022',
        poster: mockSubcollectionPosterWithSettings({
            mockPosterIndex: 3,
            overlayText: 'CL 2021 Highlights',
            overlayPosition: 'center',
            overlayTextSize: 'large',
        }),
        criteria: {
            searchId: mockSmartSearchesMapped[0].id,
            groupBy: mockMetaKeys[0],
            filters: [mockFilters[2]],
        },
    }),
    mockMirageSubcollection({
        id: '7',
        position: 1,
        collectionId: '7',
        label: 'Red cards',
        criteria: {
            searchId: mockSmartSearchesMapped[2].id,
        },
    }),
    mockMirageSubcollection({
        id: '8',
        position: 1,
        collectionId: '8',
        label: 'Interviews',
        poster: mockSubcollectionPoster({
            mockPosterIndex: 1,
            subTitleVisible: true,
            subTitleText: 'Interviews',
        }),
        criteria: {
            searchId: mockSmartSearchesMapped[3].id,
            groupBy: mockMetaKeys[1],
            filters: [mockFilters[0]],
        },
    }),
    mockMirageSubcollection({
        id: '9',
        position: 1,
        collectionId: '10',
        label: 'Promos',
        poster: mockSubcollectionPoster({
            mockPosterIndex: 3,
            subTitleVisible: true,
            subTitleText: 'My Promos',
        }),
        criteria: {
            searchId: mockSmartSearchesMapped[2].id,
        },
    }),
    mockMirageSubcollection({
        id: '10',
        position: 2,
        collectionId: '10',
        label: 'Yellow cards',
        poster: mockSubcollectionPoster({
            mockPosterIndex: 2,
        }),
        criteria: {
            searchId: mockSmartSearchesMapped[0].id,
        },
    }),
    mockMirageSubcollection({
        id: '11',
        position: 1,
        collectionId: '12',
        label: 'Matches',
        description: 'Season 21/22',
        poster: mockSubcollectionPosterWithSettings({
            mockPosterIndex: 0,
            overlayText: 'Matches 21/22',
            overlayPosition: 'top',
            overlayTextSize: 'large',
        }),
    }),
];

export const mockMirageSubcollectionsMapped: Subcollection[] = mockMirageSubcollections.map(subcollection => {
    const { criteria, ...restAttributes } = subcollection;
    return {
        ...restAttributes,
        searchId: criteria.searchId.toString(),
        ...(criteria?.groupBy &&
            criteria.filters && {
                groupBy: criteria.groupBy,
                filters: criteria.filters.map(filterObject =>
                    Object.keys(filterObject).map(key => {
                        const metaValue = filterObject[key];
                        return {
                            metaKey: key,
                            metaValue: typeof metaValue === 'string' ? [metaValue] : metaValue,
                        };
                    })
                ),
            }),
    };
});
