import { Collection, Instantiate } from 'miragejs';
import { AppRegistry } from '../server';

export const reorderPositions = (
    mirageCollections: Collection<Instantiate<AppRegistry, 'collection' | 'subcollection'>>,
    sourceIndex: any,
    destinationIndex: any
) => {
    mirageCollections.models.forEach(mirageCollection => {
        if (
            sourceIndex > destinationIndex &&
            mirageCollection.position >= destinationIndex &&
            mirageCollection.position < sourceIndex
        ) {
            mirageCollection.position = mirageCollection.position + 1;
        } else if (
            sourceIndex < destinationIndex &&
            mirageCollection.position > sourceIndex &&
            mirageCollection.position <= destinationIndex
        ) {
            mirageCollection.position = mirageCollection.position - 1;
        }
    });
};

export const increasePositionsGreaterThan = (
    mirageCollections: Collection<Instantiate<AppRegistry, 'collection' | 'subcollection'>>,
    copyCollection: any
) => {
    mirageCollections.models.forEach(mirageCollection => {
        if (mirageCollection.position > copyCollection.position) {
            mirageCollection.position = mirageCollection.position + 1;
        }
    });
};

export const decreasePositionsGreaterThan = (
    mirageCollections: Collection<Instantiate<AppRegistry, 'collection' | 'subcollection'>>,
    deleteCollection: any
) => {
    mirageCollections.models.forEach(mirageCollection => {
        if (mirageCollection.position > deleteCollection.position) {
            mirageCollection.position = mirageCollection.position - 1;
        }
    });
};
