import { Poster, PosterOption } from '../../lib/Poster';

export const mockCollectionPoster = ({
    mockPosterIndex,
    ...poster
}: Partial<Poster> & { mockPosterIndex: number }): Poster => ({
    assetId: mockPosters[mockPosterIndex].id,
    assetUrl: mockPosters[mockPosterIndex].imageUrl,
    subTitleVisible: false,
    overlayVisible: false,
    ...poster,
});

export const mockCollectionPosterWithSettings = ({
    mockPosterIndex,
    ...poster
}: Partial<Poster> & { mockPosterIndex: number }): Poster => ({
    assetId: mockPosters[mockPosterIndex].id,
    assetUrl: mockPosters[mockPosterIndex].imageUrl,
    subTitleVisible: false,
    overlayVisible: true,
    overlayText: 'Champions League',
    overlayPosition: 'center',
    overlayTextColor: 'white',
    overlayTextSize: 'large',
    overlayTextType: 'IBM Plex Sans',
    overlayBgOpacity: 0.5,
    ...poster,
});

export const mockSubcollectionPoster = ({
    mockPosterIndex,
    ...poster
}: Partial<Poster> & { mockPosterIndex: number }): Poster => ({
    assetId: mockPosters[mockPosterIndex].id,
    assetUrl: mockPosters[mockPosterIndex].imageUrl,
    subTitleVisible: false,
    overlayVisible: false,
    ...poster,
});

export const mockSubcollectionPosterWithSettings = ({
    mockPosterIndex,
    ...poster
}: Partial<Poster> & { mockPosterIndex: number }): Poster => ({
    assetId: mockPosters[mockPosterIndex].id,
    assetUrl: mockPosters[mockPosterIndex].imageUrl,
    subTitleVisible: false,
    overlayVisible: true,
    overlayText: 'Highlights overlay text',
    overlayPosition: 'bottom',
    overlayTextColor: 'white',
    overlayTextSize: 'medium',
    overlayTextType: 'IBM Plex Sans',
    overlayBgOpacity: 0.75,
    ...poster,
});

export const mockPosters: PosterOption[] = [
    {
        id: 15796,
        imageUrl: 'https://static-eu1.mediabank.me/b/NETWERK/202201/15796/15796_poster.png?v=202201241513',
        title: 'Doggo',
        category: 'Animals',
    },
    {
        id: 15804,
        imageUrl: 'https://static-eu1.mediabank.me/b/NETWERK/202201/15804/15804_poster.png?v=202201241513',
        title: 'Catto',
        category: 'Animals',
    },
    {
        id: 15803,
        imageUrl: 'https://static-eu1.mediabank.me/b/NETWERK/202201/15803/15803_poster.png?v=202201241513',
        title: 'Racco',
        category: 'Animals',
    },
    {
        id: 15802,
        imageUrl: 'https://static-eu1.mediabank.me/b/NETWERK/202201/15802/15802_poster.png?v=202201241513',
        title: 'Bamboo',
        category: 'Animals',
    },
    {
        id: 15797,
        imageUrl: 'https://static-eu1.mediabank.me/b/NETWERK/202201/15797/15797_poster.png?v=202201241513',
        title: 'Test 15797',
        category: 'Cars',
    },
    {
        id: 15798,
        imageUrl: 'https://static-eu1.mediabank.me/b/NETWERK/202201/15798/15798_poster.png?v=202201241513',
        title: 'Test 15798',
        category: 'Cars',
    },
    {
        id: 15799,
        imageUrl: 'https://static-eu1.mediabank.me/b/NETWERK/202201/15799/15799_poster.png?v=202201241513',
        title: 'Test 15799',
        category: 'Cars',
    },
    {
        id: 15800,
        imageUrl: 'https://static-eu1.mediabank.me/b/NETWERK/202201/15800/15800_poster.png?v=202201241513',
        title: 'Test 15800',
        category: 'Football',
    },
    {
        id: 15801,
        imageUrl: 'https://static-eu1.mediabank.me/b/NETWERK/202201/15801/15801_poster.png?v=202201241513',
        title: 'Test 15801',
        category: 'Football',
    },
    {
        id: 15805,
        imageUrl: 'https://static-eu1.mediabank.me/b/NETWERK/202201/15805/15805_poster.png?v=202201311546',
        title: 'Test 15805',
        category: 'Football',
    },
];
