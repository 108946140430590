import { SettingsProperties } from '../lib/PosterSettings';

export const fontTypes = [
    'IBM Plex Sans',
    'Arial',
    'Verdana',
    'Helvetica',
    'Tahoma',
    'Trebuchet MS',
    'Times New Roman',
    'Georgia',
    'Garamond',
    'Courier New',
    'Brush Script MT',
] as const;

export const fontSizes = ['small', 'medium', 'large'] as const;

export const fontSizeMap = {
    small: '1em',
    medium: '1.5em',
    large: '2.5em',
};

export const fontPositions = ['bottom', 'center', 'top'] as const;

export const fontColors = ['white', 'grey', 'black'];

export const backgroundOpacities = [0, 0.25, 0.5, 0.75, 1];

export const allFields: (keyof SettingsProperties)[] = [
    'subTitleVisible',
    'overlayVisible',
    'overlayPosition',
    'overlayTextColor',
    'overlayTextSize',
    'overlayTextType',
    'overlayBgOpacity',
];
