import { SmartSearch } from '../../lib/SmartSearch';

export const mockSmartSearches: SmartSearch[] = [
    {
        title: 'Euro 2020',
        id: '35600',
        createdBy: 'Ola Nordmann',
    },
    {
        title: 'Premier League',
        id: '44711',
        createdBy: 'Kari Nordmann',
    },
    {
        title: 'Champions League 1',
        id: '39247',
        createdBy: 'Pål Nordmann',
    },
    {
        title: 'Eliteserien',
        id: '4888',
        createdBy: 'Per Nordmann',
    },
    {
        title: 'Test error',
        id: '1234567',
        createdBy: 'Mr. error',
    },
];

export const mockSmartSearchesMapped = mockSmartSearches.map(smartSearch => ({
    ...smartSearch,
    id: parseInt(smartSearch.id),
}));
