import { SettingsProperties } from '../../lib/PosterSettings';

export const mockDefaultPosterSettings = (defaultPosterSettings?: Partial<SettingsProperties>): SettingsProperties => ({
    subTitleVisible: false,
    overlayVisible: false,
    overlayPosition: 'top',
    overlayTextColor: 'white',
    overlayTextSize: 'small',
    overlayTextType: 'IBM Plex Sans',
    overlayBgOpacity: 0.75,
    ...defaultPosterSettings,
});
