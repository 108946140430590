import { Collection } from '../../lib/Collection';
import { Criteria } from '../../lib/Criteria';
import { DeepPartial } from '../../lib/Utility';
import { mockCollectionPoster, mockCollectionPosterWithSettings } from './posters';
import { mockSmartSearchesMapped } from './smartSearches';
import { mockUserGroups } from './groups';
import { mockMetaKeys } from './metaKeys';
import { mockFilters } from './filters';
import { mockDefaultPosterSettings } from './settings';
import { getUserObject } from '../helpers/collectionSerialize';

export interface MirageCollection
    extends Omit<Collection, 'createdBy' | 'updatedBy' | 'searchId' | 'groupBy' | 'filters' | 'subcollections'> {
    createdById: string;
    updatedById: string;
    criteria?: Criteria;
    subcollectionIds?: string[] | null;
}

export interface MirageCollectionAggregated extends Omit<MirageCollection, 'createdById' | 'updatedById'> {
    createdBy: { id: number; name: string };
    updatedBy: { id: number; name: string };
}

export const mockMirageCollection = (collection: DeepPartial<MirageCollection>): MirageCollection => ({
    id: '1',
    position: 1,
    label: 'Champions League',
    description: 'Describing text',
    createdById: '1',
    updatedById: '1',
    viewable: true,
    ...collection,
    poster: {
        ...mockCollectionPoster({ mockPosterIndex: 0 }),
        ...collection.poster,
    },
    access: { type: 'groups', ...collection.access },
});

export const mockMirageCollections: MirageCollection[] = [
    mockMirageCollection({
        description: 'Everything from 2021',
        poster: mockCollectionPosterWithSettings({
            mockPosterIndex: 1,
            overlayTextColor: 'grey',
            overlayBgOpacity: 1,
            overlayPosition: 'bottom',
        }),
        access: { type: 'groups', groups: [mockUserGroups[0].id, mockUserGroups[1].id] },
        subcollectionIds: ['1', '2', '3', '4'],
        settings: mockDefaultPosterSettings(),
    }),
    mockMirageCollection({
        id: '2',
        position: 2,
        label: 'Europa League',
        createdById: '2',
        updatedById: '2',
        access: { type: 'company' },
        criteria: {
            searchId: mockSmartSearchesMapped[3].id,
            groupBy: mockMetaKeys[0],
            filters: [mockFilters[0], mockFilters[2]],
        },
    }),
    mockMirageCollection({
        id: '3',
        position: 3,
        label: 'Eliteserien',
        description: 'Norges første divisjon',
        poster: mockCollectionPosterWithSettings({
            mockPosterIndex: 2,
            overlayText: 'Eliteserien 2021',
            overlayPosition: 'top',
        }),
        createdById: '3',
        updatedById: '3',
        access: { type: 'groups', groups: [mockUserGroups[2].id] },
        criteria: {
            searchId: mockSmartSearchesMapped[2].id,
        },
    }),
    mockMirageCollection({
        id: '4',
        position: 4,
        label: 'Euros',
        poster: mockCollectionPoster({ mockPosterIndex: 3 }),
        createdById: '4',
        updatedById: '4',
        access: { type: 'groups', groups: [mockUserGroups[0].id] },
        viewable: false,
        subcollectionIds: ['5', '6'],
        settings: mockDefaultPosterSettings({ overlayTextColor: 'grey' }),
    }),
    mockMirageCollection({
        id: '5',
        position: 5,
        label: 'World Cup',
        poster: mockCollectionPosterWithSettings({
            mockPosterIndex: 1,
            overlayText: 'Qatar 2022',
            overlayPosition: 'bottom',
        }),
        createdById: '5',
        updatedById: '5',
        access: { type: 'groups', groups: [mockUserGroups[0].id, mockUserGroups[1].id, mockUserGroups[2].id] },
        criteria: {
            searchId: mockSmartSearchesMapped[0].id,
        },
    }),
    mockMirageCollection({
        id: '6',
        position: 6,
        label: 'NM',
        description: 'Norgesmesterskap',
        poster: mockCollectionPoster({ mockPosterIndex: 3 }),
        createdById: '6',
        updatedById: '6',
        access: { type: 'company' },
        criteria: {
            searchId: mockSmartSearchesMapped[1].id,
            groupBy: mockMetaKeys[2],
            filters: mockFilters,
        },
    }),
    mockMirageCollection({
        id: '7',
        position: 7,
        label: 'Toppserien',
        createdById: '7',
        updatedById: '7',
        access: { type: 'groups', groups: [mockUserGroups[0].id] },
        subcollectionIds: ['7'],
        settings: mockDefaultPosterSettings(),
    }),
    mockMirageCollection({
        id: '8',
        position: 8,
        label: 'OBOS-ligaen',
        poster: mockCollectionPosterWithSettings({
            mockPosterIndex: 2,
            overlayText: 'OBOS OBOS',
            overlayTextColor: 'grey',
            overlayBgOpacity: 0.25,
        }),
        createdById: '8',
        updatedById: '8',
        access: { type: 'groups', groups: [mockUserGroups[1].id] },
        viewable: false,
        subcollectionIds: ['8'],
    }),
    mockMirageCollection({
        id: '9',
        position: 9,
        label: 'Premier League',
        poster: mockCollectionPoster({ mockPosterIndex: 2 }),
        createdById: '9',
        updatedById: '9',
        access: { type: 'groups', groups: [mockUserGroups[4].id, mockUserGroups[1].id] },
        criteria: {
            searchId: mockSmartSearchesMapped[4].id,
        },
    }),
    mockMirageCollection({
        id: '10',
        position: 10,
        label: 'Bundesliga',
        poster: mockCollectionPoster({ mockPosterIndex: 1 }),
        createdById: '10',
        updatedById: '10',
        access: { type: 'groups', groups: [mockUserGroups[3].id, mockUserGroups[4].id] },
        subcollectionIds: ['9', '10'],
    }),
    mockMirageCollection({
        id: '11',
        position: 11,
        label: 'EFL Championship',
        poster: mockCollectionPoster({ mockPosterIndex: 3 }),
        description: 'Second-highest divison overall in the English football league system',
        createdById: '11',
        updatedById: '11',
        access: { type: 'groups', groups: [mockUserGroups[2].id] },
        criteria: {
            searchId: mockSmartSearchesMapped[4].id,
        },
    }),
    mockMirageCollection({
        id: '12',
        position: 12,
        label: 'Ligue 1',
        poster: mockCollectionPoster({ mockPosterIndex: 0 }),
        createdById: '12',
        updatedById: '12',
        access: { type: 'company' },
        viewable: false,
        subcollectionIds: ['11'],
        settings: mockDefaultPosterSettings({ overlayPosition: 'center' }),
    }),
    mockMirageCollection({
        id: '13',
        position: 13,
        label: 'Serie A',
        poster: mockCollectionPoster({ mockPosterIndex: 2 }),
        createdById: '13',
        updatedById: '13',
        access: { type: 'groups', groups: [mockUserGroups[0].id, mockUserGroups[1].id] },
    }),
    mockMirageCollection({
        id: '14',
        position: 14,
        label: 'La Liga',
        createdById: '14',
        updatedById: '14',
        access: { type: 'company' },
        criteria: {
            searchId: mockSmartSearchesMapped[3].id,
            groupBy: mockMetaKeys[0],
            filters: [mockFilters[0]],
        },
    }),
];

export const mockMirageCollectionsMapped: Collection[] = mockMirageCollections.map(mirageCollection => ({
    ...mirageCollection,
    createdBy: getUserObject(mirageCollection.createdById),
    updatedBy: getUserObject(mirageCollection.updatedById),
}));
