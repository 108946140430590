import { AllGlobalSettings } from '../../lib/PosterSettings';

export const mockMirageGlobalSettings: AllGlobalSettings = {
    collection: {
        settings: {
            subTitleVisible: false,
            overlayVisible: false,
            overlayPosition: 'center',
            overlayTextColor: 'white',
            overlayTextSize: 'large',
            overlayTextType: 'IBM Plex Sans',
            overlayBgOpacity: 0.5,
        },
        editable: ['overlayVisible', 'overlayPosition', 'overlayTextColor', 'overlayTextType', 'overlayBgOpacity'],
    },
    subCollection: {
        settings: {
            subTitleVisible: false,
            overlayVisible: false,
            overlayPosition: 'bottom',
            overlayTextColor: 'white',
            overlayTextSize: 'medium',
            overlayTextType: 'IBM Plex Sans',
            overlayBgOpacity: 0.75,
        },
        editable: [
            'subTitleVisible',
            'overlayVisible',
            'overlayPosition',
            'overlayTextColor',
            'overlayTextSize',
            'overlayTextType',
        ],
    },
};
