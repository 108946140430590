import { mockMirageUsers } from '../fixtures/users';
import { mockPosters } from '../fixtures/posters';
import { pick } from 'lodash';
import { Poster } from '../../lib/Poster';

type JsonCollectionData = { attributes: {} };
type JsonCollection = { data: JsonCollectionData | JsonCollectionData[]; included?: JsonCollectionData[] };
type ObjectCollectionModel = { attrs: { createdById: string; updatedById: string } };
type ObjectCollection = ObjectCollectionModel | { models: ObjectCollectionModel[] };
type Request = { queryParams: { 'fields[collections]': string } };
type Sort = { attributes: { position: number } };

export const addCreatedByAndUpdatedByProperties = (json: JsonCollection, object: ObjectCollection) => {
    if (Array.isArray(json.data) && json.data.length && 'models' in object) {
        return json.data.map((collection, index) => {
            const { createdById, updatedById } = object.models[index].attrs;
            const createdBy = getUserObject(createdById);
            const updatedBy = getUserObject(updatedById);
            return {
                ...collection,
                attributes: { ...collection.attributes, createdBy, updatedBy },
            };
        });
    } else if (!Array.isArray(json.data) && !('models' in object)) {
        const { createdById, updatedById } = object.attrs;
        const createdBy = getUserObject(createdById);
        const updatedBy = getUserObject(updatedById);
        return {
            ...json.data,
            attributes: { ...json.data.attributes, createdBy, updatedBy },
        };
    }
};

export const getUserObject = (id: string) => {
    const user = mockMirageUsers.find(user => user.id === id)!;
    return { id: parseInt(user.id), name: user.name };
};

export const sortSubcollections = (json: { included: [] }) => ({
    ...json,
    included: json.included.sort((a: Sort, b: Sort) => a.attributes.position - b.attributes.position),
});

export const addPosterAssetUrl = (poster: Poster) => ({
    ...poster,
    assetUrl: mockPosters.find(option => option.id === poster.assetId)?.imageUrl,
});

export const pickFieldSet = (json: JsonCollection, request: Request) => {
    const fields = request.queryParams['fields[collections]'].split(',');

    if (json.included)
        return json.included.map(included => ({
            ...included,
            attributes: pick(included.attributes, fields),
        }));
    else if (!Array.isArray(json.data))
        return {
            ...json.data,
            attributes: pick(json.data.attributes, fields),
        };
};
